@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Indie+Flower&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:wght@400');

:root {
  --black: #323232;
  --background: #323232;
  --fontColour: #323232;

  --grayOne: #777777;
  --grayTwo: #e8e8e8;
  --grayThree: #f6f6f6;
  --white: #ffffff;

  --border: 1px solid var(--primary);
  --boxShadow: 4px 4px 0 0 var(--grayTwo);
  --fontFamily: 'Montserrat', sans-serif;
  --logoFont: var(--fontFamily);
  --borderRadius: 8px;

  --primary: #FFFFFF;
  --primaryTwo: #619C87;
}

* {
  margin: 0;
  padding: 0;
}

html,
body {
  height: 100%;
}

body,
button,
input {
  font-family: var(--fontFamily);

  color: var(--fontColour);
  font-size: 16px;
}

body { font-size: 16px; }
input, select { font-size: 100%; }

body {
  margin: 0;
  background-color: white;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  height: 100%;
}

button {
  cursor: pointer;
}

input {
  cursor: text;
}

button:focus,
textarea:focus,
input:focus {
  outline: none;
}

.bmc {
  display: none;

  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 5;
}

.bmc > img {
  height: 42px;
}